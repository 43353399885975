

.country-selector-for-available-phone-numbers {

}

.country-selector-for-available-phone-numbers-option-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  .phone-country-code {
    min-width: 50px;
  }
}