@import '../../../frontend/theme/colors';

.smart-chain-error-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;

  .smart-chain-error-row {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .smart-chain-error-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    align-items: stretch;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 0.8rem;
    font-style: italic;
  }

  .smart-chain-error-body {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: stretch;
    align-items: stretch;
  }

  .smart-chain-error-row.expand-both {
    .smart-chain-error-context {
      flex-basis: calc(45% - 40px);
    }

    .smart-chain-error-result {
      flex-basis: calc(55% - 40px);
    }
  }

  .smart-chain-error-row.expand-left {
    .smart-chain-error-context {
      display: none;
    }

    .smart-chain-error-result {
      flex-basis: calc(100% - 40px);
    }
  }

  .smart-chain-error-row.expand-right {
    .smart-chain-error-context {
      flex-basis: calc(100% - 40px);
    }

    .smart-chain-error-result {
      display: none
    }
  }

  .smart-chain-error-column {
    flex-basis: 30%;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: stretch;
    align-items: stretch;

    .custom-json-view {
      flex-grow: 1;
    }

    span.sample-date {
      padding: 5px;
    }
  }

  .smart-chain-error-actions {
    display: flex;
    flex-basis: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    gap: 0;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #f3fff3;

    .MuiButtonBase-root.reprocess-button {
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .MuiCircularProgress-root {
        color: $primary-main;
      }
    }
  }

  .no-errors {
    padding: 20px;
  }
}