

.google-calendar-integration-widget {
  flex-grow: 1;
  text-align: left;

  .consent-widget {
    a {
      color: blue;
      text-decoration: underline;
      margin-right: 3px;
    }
  }
}