@import "../theme/colors";

.accordion {
  box-shadow: none;

  .accordion-summary {
    padding: 8px 0px;

    .summary-title {
      color: $mid-black;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      &.small-title {
        font-size: 14px;
      }
    }
  }

  .accordion-details {
    padding: 8px 0px;
  }
}
