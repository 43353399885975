@import "../theme/colors";

.left-side-menu {
  background-color: $primary-main;
  border-right: 1px solid $primary-light;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;

  flex-basis: 18vw;

  .MuiList-root {
    gap: 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  @media (max-width: 600px) {
    flex-basis: auto;
    .MuiListItemIcon-root {
      min-width: inherit;
    }
    .MuiTypography-root {
      display: none;
    }
    .MuiListItemButton-root {
      padding: 4px 8px;
    }

    .MuiList-root {
      .MuiList-root {
        .MuiListItemButton-root {
          padding-left: 16px;
        }
      }
    }

    .MuiIconButton-edgeEnd {
      margin-left: 0;
      padding-left: 0;
    }
  }

  &:not(.expanded) {
    flex-basis: auto;

    .MuiListItemIcon-root {
      min-width: inherit;
    }

    .MuiTypography-root {
      display: none;
    }

    .MuiListItemButton-root {
      padding: 11px 16px;
    }

    .MuiList-root {
      .MuiList-root {
        .MuiListItemButton-root {
          padding-left: 32px;
        }

        .MuiList-root {
          .MuiListItemButton-root {
            padding-left: 48px;
          }
        }
      }
    }

    .MuiIconButton-edgeEnd {
      margin-left: 0;
      padding-left: 0;
    }
  }

  &.expanded {
    .MuiList-root {
      .MuiList-root {
        .MuiListItemButton-root {
          padding-left: 32px;
        }

        .MuiList-root {
          .MuiListItemButton-root {
            padding-left: 48px;
          }
        }
      }
    }
  }

  .MuiTypography-root {
    color: $primary-text;
    text-decoration: none;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    a,
    a:hover {
      text-decoration: none;
    }
  }

  .MuiSvgIcon-root {
    color: lighten($primary-text, 10%);
    position: relative;
    top: 0;
  }

  /**
   * Remove the underline from all the links
   */
  a:visited,
  a:link,
  a:active,
  a:hover {
    text-decoration: none;
  }

  .MuiListItem-root a {
    width: 100%;
  }

  .MuiListItemButton-root {
    width: calc(100% - 15px);
    margin-left: 5px;
    margin-right: 10px;
    border-radius: 5px;

    &:hover {
      background-color: darken($accent-light, 2%);

      .MuiTypography-root {
        color: $accent-text !important;
      }
    }
  }

  .MuiListItem-root a {
    &.active {
      .MuiListItemButton-root {
        background-color: darken($accent-light, 0%);

        .MuiTypography-root {
          color: $accent-text !important;
        }

        svg {
          fill: $accent-text;
        }
      }
    }

    &:not(.active) {
      .MuiListItemButton-root {
        svg {
          fill: lighten($primary-text, 10%);
        }
      }
    }
  }

  .MuiListItem-root {
    padding: 0;
  }

  .menu-pages-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: flex-start;
    overflow-y: auto;

    &.unauthenticated {
      justify-content: center;
      align-items: center;
    }

    .collapsible-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-grow: 1;
    }
  }

  .please-login-text {
    color: $primary-text;

    margin: 20px;
  }
}
