
.document-template-page-settings-tab {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  padding: 40px;

  & > .MuiPaper-root {
    padding: 20px 20px 30px 20px;
    gap: 20px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .page-list-wrapper {

  }

}
