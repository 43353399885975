

.conversation-view-page {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 30px;

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  h1 {
    text-align: left;
  }

  .field-help {
    text-align: left;
    color: grey;
    font-size: 0.8em;
    font-style: italic;
  }

  .field-label {
    text-align: left;
  }

  .delete-button {
    max-width: 150px;
  }

  .save-button {
    flex-grow: 1;
  }

  .send-initial-button {
    max-width: 150px;
  }

  .buttons-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
  }

  .conversation-events-card {
    max-width: 1200px;
  }

  .conversation-details-card {
    text-align: left;

    .MuiCardContent-root {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 5px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
      }
    }
  }
}