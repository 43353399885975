.scheduling-rules-page {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  align-items: stretch;
  text-align: left;

  h1, h2, span {
    text-align: left;
  }

  .MuiPaper-root {
    padding: 20px;
  }

  .scheduling-rules-card {
    margin-top: 30px;
  }

  .scheduling-rules-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .max-days-in-future-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
  }

  .default-event-length-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    max-width: 500px;
  }

  .event-limit-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 10px;
    background-color: #fafafa;
    border-radius: 8px;
    gap: 10px;
    max-width: 800px;
    margin-bottom: 20px;

    input, select, .MuiSelect-select {
      background-color: white;
    }
  }

  .available-hours-explainer {
    position: relative;
    top: -10px;
    padding-bottom: 30px;
  }

  .buffer-time-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    text-align: left;

    span {
      flex-basis: 40%;
    }

    .buffer-time-input {
      flex-basis: 60%;
      flex-grow: 1;
      min-width: 250px;
    }
  }

  h3 {
    text-align: left;
  }

  .minimum-notice-section, .start-time-increment-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    text-align: left;

    .minimum-notice-time-units, .start-time-increment-field {
      width: 125px;
    }

    .minimum-notice-input {
      min-width: 250px;
    }
  }

  .time-zone-section {
    display: flex;
    flex-direction: row;
    gap: 25px;
    justify-content: flex-start;
    align-items: center;
  }

  .buttons-bar {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}