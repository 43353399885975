@import "../theme/colors";

.MuiButton-root.white-button {
  position: relative;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  border: 1px solid #999;
  border-radius: 20px;
  font-size: 12px;
  text-transform: none;
  box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  span {
    position: relative;
    font-weight: 700;
  }

  &:hover {
    background-color: #f0f0f0;
    box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
  }

  .MuiCircularProgress-root {
    color: $accent-text;
    position: relative;
    top: 3px;
  }
}
