

.outbound-state-viewer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  span {
    white-space: pre-wrap;
  }
}