


.health-check-summary {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .MuiList-root {
        flex-grow: 0;
        .MuiListItem-root {
            padding: 5px;
            &:last-child {
                border-bottom: 1px solid #aaa;
            }
            border-top: 1px solid #aaa;
            border-left: 1px solid #aaa;
            border-right: 1px solid #aaa;
        }
    }
}