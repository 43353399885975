@import "../../../frontend/theme/colors";

.smart-chain-editor-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //flex-grow: 1;

  .MuiToolbar-root {
    justify-content: space-between;
    border-bottom: 1px solid $primary-light;
  }

  .top-bar-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .MuiCircularProgress-svg {
    color: $primary-text;
  }
  .MuiToggleButton-root {
    color: $primary-text;
  }
}