@import "../../../frontend/theme/colors";


.smart-chain-step-type-selector {
  width: 250px;


  .MuiOutlinedInput-root {
    //padding: 2px;
    color: $primary-text;
    border-color: white !important;
    &:focus {
      border-color: white !important;
    }
  }

  .MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input {
    padding-left: 40px !important;
  }

  .MuiFormControl-root {
    border-color: white !important;
    > {
      border-color: white !important;
    }
    &:focus {
        border-color: white !important;
    }
    .MuiInputBase-root {
      padding-left: 42px;
    }
  }

  .smart-chain-step-type-value {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
  }

  .MuiListItemText-root {
    white-space: wrap;
  }
}

#smart-chain-step-type-listbox.MuiAutocomplete-listbox {
  max-height: 70vh;
}


.smart-chain-step-type-menu-entry {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .MuiTypography-root {
    white-space: wrap;
  }
}