@import "../../../theme/colors";

.field-list-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;
  cursor: pointer;

  .field-list-item-key {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 40%;

    .field-list-item-key-input {
      flex: 1;
    }
  }

  .field-list-item-actions {
    display: flex;
    gap: 8px;
    align-items: center;

    .remove-field-button {
      color: $error;
      cursor: pointer;
    }

    .expand-button {
      color: $mid-black;
      cursor: pointer;
    }
  }
}

.field-list-item-content {
  margin-left: 12px;
  .field-content {
    margin-top: 4px;
    margin-left: 16px;
  }
}
