


.subscription-widget {
  .page-section {
    > .MuiPaper-root {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
  }
}