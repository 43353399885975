@import "../../theme/colors";

.schema-editor-container {
  display: flex;
  position: relative;
  gap: 8px;
  flex-direction: column;
  background-color: white;
  padding: 16px;
  margin: 16px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.12);

  .schema-editor-toggler {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;

    .toggle-button {
      border-radius: 4px;
      padding: 6px;
      border: 1px solid $border-color;
      box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
    }
  }

  &.no-box-shadow {
    box-shadow: none;
  }

  &.no-padding {
    padding: 0;
  }
  &.no-margin {
    margin: 0;
  }

  .editor-area {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;

    .title {
      color: $mid-black;
      font-size: 16px;
      font-weight: 700;
    }

    .schema-editor {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }
}
