

.knowledge-chunk-viewer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  pre {
    white-space: pre-wrap;
  }

  .accordian-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: flex-start;
    align-items: stretch;
  }
}