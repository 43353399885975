/**
 * Note that these theme colors should stay in sync with the colors in theme.js. If modifying one
 * You should modify the other.
 */
$primary-light: #ffffff;
$primary-main: #ffffff;
$primary-dark: #cccccc;
$primary-contrast: #ffffff;
$primary-text: rgb(54, 65, 82);
$primary-text-dark: #111111;

$accent-text: rgb(103, 58, 183);
$accent-text-dark: darken($accent-text, 30%);
$accent-light: rgb(231, 222, 244);

$secondary-light: #b37a51;
$secondary-main: #67310a;
$secondary-dark: #4b1400;
$secondary-contrast: #ffffff;

$border-color: #ececed;
$mid-black: #4f4b5c;
$error: #cf2a2a;

$page-background: rgb(248, 248, 248);
