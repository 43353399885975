.custom-data-object-form {
  padding: 0px 16px;

  .file-preview-wrapper {
    max-width: 25vw;
  }

  .MuiCollapse-vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
  }
}
