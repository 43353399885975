.spinner-button {
  position: relative;
  .MuiCircularProgress-root {
    color: white;
  }

  .button-text-wrapper {
    &.hidden-button-text {
      opacity: 0;
    }
  }

  .spinner-wrapper {
    position: absolute;
    & > * {
      position: relative;
      top: 5px;
    }
  }

  .icon-wrapper {
    position: absolute;
    & > * {
      position: relative;
      top: 1px;
    }
  }
}
