@import '../../../frontend/theme/colors';


.schema-field-list {
  .MuiCollapse-root {
    padding-left: 4px;
    padding-right: 4px;
  }

  .MuiListItem-root {
    padding-left: 4px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiList-root {
    .MuiList-root {
      padding-left: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }

  .MuiListItemIcon-root, .MuiListItemAvatar-root {
    position: relative;
    top: -1px;
    min-width: 32px;

    .MuiAvatar-root {
      background-color: $primary-main;

      .unknown-type-text {
        position: relative;
        top: 2px;
        left: 0.5px;
        font-size: 1.0rem;
        font-weight: bold;
      }
    }
  }

  .MuiListItemButton-root {
    padding: 0;
  }

  .MuiListItemText-primary {
    //word-break: break-word;
    font-family: monospace;
    font-size: 0.8rem;
  }

  .schema-field-item {
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
  }
}




.schema-field-additional-details-and-description-tooltip.MuiTooltip-tooltip {
  font-size: 14px;
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  padding: 20px;
  max-width: 30vw;
  white-space: pre-wrap;
}