.bulk-document-upload-page {
  .bulk-document-upload-page-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .upload-button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 500px;
    min-height: 150px;
    border: 1px dashed #c4c4c4;
    cursor: pointer;
  }

  .MuiCardContent-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
