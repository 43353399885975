h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
}

.container {
  padding: 32px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.row {
    flex-direction: row;
    align-items: center;
  }

  &.indent {
    margin-left: 32px;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 16;

  &.indent {
    margin: 0px 32px;
  }
}

.detail-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: all 0.3s ease-in-out;
}
