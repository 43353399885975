@import "../../../frontend/theme/colors";

.filled-intake-page {
  .MuiPaper-root {
    padding: 20px;
  }

  .completed-text {
    font-size: 14px;
    font-weight: 700;
  }

  .answer-text {
    font-size: 14px;
    font-weight: 500;
  }

  .data-points-header {
    font-size: 14px;
    font-weight: 700;
    //margin-bottom: 20px;
  }

  .question-answer-header {
    .MuiSvgIcon-root {
      margin-right: 20px;
    }
  }
}
