

.calendars-list-page {
  .MuiPaper-root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }

}