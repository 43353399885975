
.outbound-rules-editor {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

  & > .MuiList-root {
    max-width: none;
  }
  .outbound-rule-editor-area-list-item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }
}
