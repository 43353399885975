

.system-health-page {
  .page-section > .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-grow: 1;
    align-items: stretch;
  }
}

