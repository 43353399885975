
.perform-analysis-page {
  .perform-analysis-card {
    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}