.page-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 8px;

  .page-header-content {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  h1 {
    font-size: 22px;
    text-align: left;
    font-weight: bold;
  }
}
