

.checkboxes-input {
  .checkbox-label {
    position: relative;
    top: 5px;
  }

  .MuiCheckbox-root {
    svg {
      background-color: white;
    }
  }
}