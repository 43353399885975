

.table-view {
  all: unset;
  .table-element-wrapper {
    height: calc(100vh - 250px);
  }

  .ag-watermark {
    opacity: 0 !important;
  }

  .ag-root-wrapper {
    // Fix the border so that the table border
    // just becomes the border of the underlying
    // paper element.
    border-radius: 0;
    border: 0;
  }

  .show-only-my-data-option {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}