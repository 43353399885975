@import "../theme/sizes";
@import "../theme/colors";

.environment-menu {
  .title-button {
    color: $accent-text-dark;
    flex-grow: 0;
    padding: 15px;
    font-size: 24px;
    font-weight: bold;
    text-transform: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  }
}

.environment-selector {
  .MuiPaper-root {
    background-color: $primary-main;
    .MuiMenuItem-root {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 30px;
      padding-right: 30px;
      background-color: $primary-main;
      &:hover {
        background-color: $primary-dark;
      }
    }

    .MuiButtonBase-root {
      color: $accent-text-dark;
      font-size: 18px;
      font-weight: bold;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }
  }
}