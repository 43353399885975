

.smart-chain-step-list {
  min-width: 200px;
  padding-bottom: 50px;

  .step-list-header-row {

  }

  .MuiListItemAvatar-root, .MuiListItemIcon-root {
    min-width: 40px;
  }

  .MuiAvatar-root {
    font-size: 16px;
    font-weight: bold;
    font-family: monospace;
  }
}