@import "../../../frontend/theme/colors";

.smart-chain-whole-chain-view {
  height: 100%;

  & > .editor-tab {
    height: calc(100vh - 174px);
    background-color: rgb(30, 30, 30);

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    overflow: auto;

    &.hidden {
      display: none;
    }
    &.visible {

    }
  }

  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary {
    color: $primary-text-dark;
    text-transform: none;
    font-size: 1.0em;
  }

  .MuiTabs-indicator {
    background-color: skyblue;
  }

  .output-schema {
    margin: 20px;
    padding: 20px;
  }
}
