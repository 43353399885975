@import "../../../theme/colors";

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    font-size: 14px;
    font-weight: 600;
  }

  .placeholder {
    color: #8d8a95;
    font-style: italic;
  }
}

.chipBox {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.helperText {
  font-size: 14px;
  margin: 3px 0px;
}

.listItem {
  display: flex;
  gap: 8px;
  align-items: center;

  h4 {
    font-size: 14px;
    color: $mid-black;
    font-weight: 500;
    letter-spacing: normal;
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    color: $mid-black;
  }
}
