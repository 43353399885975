

.form-input {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  text-align: left;
  gap: 5px;

  .MuiInputBase-root {
    background-color: white;
    input {
      &::placeholder {
        font-style: italic;
      }
    }
  }
}