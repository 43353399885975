@import "../theme/sizes";
@import "../theme/colors";

.top-bar {
  height: $top-bar-size;
  border-bottom: 1px solid $primary-light;
  display: flex;
  flex-direction: row;
  background-color: $primary-main;
  justify-content: space-between;
  color: $accent-text-dark;

  .logo-area {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.environment-menu-enabled {
      padding-left: 0;
    }

    color: $accent-text-dark;
    flex-grow: 0;
    padding: 15px;
    font-size: 24px;
    font-weight: bold;

    .logo-text {
      margin-right: 10px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }

    img.logo-image {
      height: 32px;
    }
  }

  .right-container {
    display: flex;
    align-items: center;
    gap: 16px;

    .chat-button {
      box-shadow: none;
      padding: 8px;
      font-size: 12px;
      font-weight: 600;
    }
  }
}
