.form-array-section {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .add-item-button {
    display: block;
  }

  .array-item {
    display: flex;
    flex-direction: column;

    .delete-button {
      display: flex;
      justify-content: flex-end;
    }
  }
}
