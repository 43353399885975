@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: 243 75% 59%;
    --primary-foreground: 0 0% 100%;
    --background: 0 0% 100%;
    --foreground: 221 39% 11%;
    --placeholder: 220 9% 46%;
    --destructive: 0 75% 50%;
    --destructive-foreground: 0 0% 100%;
    --secondary: 220 75% 59%;
    --secondary-foreground: 0 0% 100%;
    --accent: 160 75% 55%;
    --accent-foreground: 0 0% 100%;
    --border: 213 27% 84%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 218 15% 59%;

    --slate-50: 210 40% 98%;
    --slate-200: 214 32% 91%;
    --slate-300: 213 27% 84%;
    --slate-400: 220 9% 46%;
    --slate-500: 215 16% 47%;
    --slate-950: 229 84% 5%;

    --white: 0 0% 100%;
    --black: 0 0% 0%;

    --indigo-50: 226 100% 97%;
    --indigo-100: 226 100% 94%;
    --indigo-300: 226 100% 94%;
    --indigo-600: 243 75% 59%;

    --icon-input: 220 9% 46%;
    --icon-static: 215 28% 17%;
    --icon-blue: 226 71% 40%;

    --gray-900: 221 39% 11%;
    --gray-600: 215 14% 34%;
    --gray-200: 220 13% 91%;

    --rose-600: 347 77% 50%;

    --info: 239 75% 65%;
    --success: 171 48% 40%;
    --warning: 23 80% 56%;
    --danger: 347 100% 49%;

    --ring: 243 75% 59%;
    --ring-error: 347 100% 49%;
    --checked: 243 75% 59%;

    --radius-xs: 6px;
    --radius-sm: 8px;
    --radius-md: 12px;
    --radius-lg: 16px;
  }

  .dark {
    --primary: 243 75% 59%;
    --primary-foreground: 0 0% 100%;
    --background: 224 71% 4%;
    --foreground: 0 0% 100%;
    --placeholder: 220 9% 75%;
  }
}

@layer base {
  body {
    @apply bg-background text-foreground;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

body {
  margin: 0;
  padding: 0;
}

html {
  tab-size: 4;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-feature-settings: normal;
  font-variation-settings: normal;
}
