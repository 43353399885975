


.web-chat-configuration-page {
  .code-snippet {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    font-size: 14px;
    font-family: monospace;
    white-space: pre-wrap;
  }

  .avatar-preview {
    max-width: 100px;
    max-height: 100px;
  }

  .background-watermark-preview {
    max-width: 300px;
    max-height: 300px;
  }
}