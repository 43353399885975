

.select-microsoft-calendars-to-synchronize-widget {
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #fafafa;
  padding: 10px;
  border-radius: 5px;
  flex-grow: 1;
  text-align: left;
}