


.calendar-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 15px;


  .calendar-buttons-bar {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-start;
  }

  .third-party-calendar-widgets {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    gap: 20px;

    .MuiPaper-root {
      flex-grow: 1;
      flex-basis: 50%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: stretch;
    }
  }
}