.custom-data-schema-form {
  padding: 0px 16px;

  .file-preview-wrapper {
    max-width: 25vw;
  }
  
  /* Remove the duplicate checkbox label */
  .MuiTypography-root.MuiTypography-label.checkbox-label {
    display: none;
  }
  
  /* Style for the smart chain binding selector to match other select fields */
  .smart-chain-binding-wrapper {
    margin-top: 16px;
    
    #outbound-smart-chain-binding-selector {
      width: 100%;
      
      .MuiInputBase-root {
        height: 40px;
        font-size: 1rem;
      }
      
      /* Hide the original label since we're using InputLabel */
      .MuiInputLabel-root {
        display: none;
      }
      
      .MuiOutlinedInput-notchedOutline {
        border-color: rgba(0, 0, 0, 0.23);
        
        legend {
          display: none; /* Hide the legend that creates the box behind the label */
        }
      }
    }
  }
  
  /* Style for all selector components in the outbound rules section */
  .MuiFormControl-root {
    .MuiInputBase-root {
      background-color: white;
    }
    
    /* Make the label smaller */
    .MuiInputLabel-root {
      font-size: 0.875rem;
      transform: translate(14px, 9px) scale(1);
      
      &.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }
    }
  }
}
