
.prospera-chat-message-input-box {

  .MuiOutlinedInput-root {
    fieldset {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-radius: 0;
      border-top-width: 1px !important;
    }

    &:hover,&:focus,&:active {
      fieldset {
        border-top-width: 1px;
      }
    }
  }

  .MuiInputBase-root {

  }

  textarea {

  }

  .MuiButtonBase-root {

  }
}

