.voice-selector {
  flex-grow: 1;
  width: 100%;

  h4 {
    text-align: center;
    margin-bottom: 5px;
  }

  .generation-description {
    text-align: center;
    font-size: 11px;
    color: grey;
    margin-bottom: 10px;
  }


  .price-text {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    color: #444;
    font-style: italic;
  }

  .voice-selector-columns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 20px;
    gap: 30px;
    flex-wrap: wrap;
    width: 100%;

    .MuiList-root {
      padding-top: 0;
      padding-bottom: 0;
    }

    .voice-selector-generation-group {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: stretch;
      &.columns-4 {
        width: 100%;
        max-width: 100%;
      }
    }

    .voice-selector-generation-group-columns {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: stretch;
      flex-wrap: wrap;

      & > * {
        flex-basis: 200px;
        max-width: 200px;
        width: 200px;
        margin-right: 8px;
        overflow: hidden;
      }
    }

    .MuiListItem-root {
      margin-bottom: 8px;

      .MuiListItemButton-root {
        background-color: #f5f5f5;
        border-radius: 16px;
        transition: background-color 0.2s ease;
        
        &:hover {
          background-color: #e0e0e0;
        }
      }
      
      .MuiRadio-root {
        margin-right: -12px;
      }

      .MuiListItemText-primary {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.75rem;
      }
    }
  }
}