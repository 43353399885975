.prospera-labs-agent-platform-chat-widget-conversation-history {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 3px;

  .date-separator {
    width: 90%;
    margin: 50px auto 0;
    border-top: 1px dashed #ddd;
    position: relative;
    
    .date-header {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      background: white;
      padding: 0 15px;
      color: #888;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .event {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    max-width: calc(max(80%, 280px));
    gap: 0;

    &.date-different {
      padding-top: 50px;
    }

    &:first-child {
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 30px;
    }

    .event-file {
      max-width: 35vw;
      margin-left: 55px;
    }

    .event-loading-bar {
      margin-top: 20px;
      margin-left: 65px;
      margin-right: 5px;
      margin-bottom: 30px;
    }

    .event-inner {
      display: flex;
      flex-direction: row;
      white-space: pre-wrap;
      text-align: left;
      align-items: flex-start;
      gap: 0;
    }

    .event-role-bot {
      margin-right: calc(max(5px, 1vw));
      position: relative;
      top: 10px;
    }

    .event-role-user {
      margin-left: calc(max(5px, 1vw));
      position: relative;
      top: 10px;
    }

    &.user {
      align-self: flex-end;
    }

    &.assistant {
      align-self: flex-start;
    }

    &.assistant {
      &:not(.user_interactor.send_message) {
        margin-bottom: 25px;
      }
    }

    .MuiPaper-root {
      border-radius: 23px;
    }

    .header-alert-combo-widget {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: stretch;
      justify-content: stretch;
      padding-top: 8px;
    }


    &.user_interactor.send_message {

    }

    &.user_interactor.send_message {
      .MuiPaper-root {
        background-color: rgb(233, 236, 241);
        color: #444;
      }
    }

    .event-header {
      white-space: nowrap;
      font-size: 13px;
      color: #aaa;
      padding-left: 5px;
      @media (max-width: 600px) {
        font-size: 11px;
      }

      .MuiSvgIcon-root {
        color: #bbb;
        position: relative;
        top: -1px;
      }

      .role-name {
        font-weight: bold;
        color: rgb(110, 160, 204);
        margin-left: 5px;
        margin-right: 5px;
      }

      &.user {
        text-align: right;

        .role-name {
          margin-left: 15px;
        }
      }

      &:not(.user) {
        text-align: left;

        .role-name {
          margin-right: 15px;
        }
      }
    }

    .event-text {
      font-family: Helvetica, Arial, sans-serif;

      font-size: 14px;

      /** Set different font size for mobile */
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }

    .MuiAlert-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .MuiAlert-icon {
      display: none;
    }
  }

  .MuiAvatar-root {
    background-color: rgb(205, 208, 213);
  }
}