

.run-synthetic-user-page {

  // Styles for the form container
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .MuiPaper-root {
    padding: 20px;
  }
}