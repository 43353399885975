.business-info-form {
  // Styles for the form container
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .MuiPaper-root {
    padding: 20px;
  }

  label.MuiFormLabel-root {
    font-size: 16px;
    padding-top: 3px;
  }

  // Styles for form elements
  .form-input {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }

  .form-button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }

  .business-info-card {
    form {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }
  }

  .buttons-bar {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .MuiButton-root {
      border-radius: 30px;
      font-size: 12px;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .business-info-options {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: space-between;
    align-items: stretch;
    gap: 10px;
  }

  .business-info-option-box {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    flex-basis: 50%;
    justify-content: space-between;
  }

  .website-url-row {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: stretch;
    justify-content: stretch;

    .MuiFormControl-root {
      flex-grow: 1;
    }

    .MuiButton-root {
      position: relative;
      margin-top: 15px;
      margin-bottom: 8px;
    }
  }
}