
.weekly-availability-widget {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: calc(max(35vw, 700px));
  max-width: 800px;

  background-color: #fafafa;
  border-radius: 8px;

  input, select, .MuiSelect-select {
    background-color: white;
  }

  .title-text {
    font-weight: bold;
    text-align: left;
  }

  .day-of-week-availability {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    gap: 10px;
  }

  .day-of-week-checkbox-section {
    display: flex;
    flex-direction: row;
    flex-basis: 35%;
    align-items: center;
    position: relative;
    top: 20px;
  }

  .day-of-week-availability-segments-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 10px;
    flex-basis: 65%;
    flex-grow: 1;
  }

  .availability-segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    gap: 10px;
  }

  .day_of_week_is_enabled {
    //color: red;
  }

  .day_of_week_text {
    text-align: left;
  }

  .start_hour {
    flex-basis: 250px;
  }

  .end_hour {
    flex-basis: 250px;
  }

  .end-buttons {
    flex-basis: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .delete_button {
    &.hidden-delete-button {
      visibility: hidden;
    }
  }
}
