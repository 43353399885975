.onboarding-checklist {
  width: calc(min(80vw, 400px));

  .MuiPaper-root {
    padding: 0;
    align-items: stretch;
  }

  .onboarding-checklist-header {
    svg.MuiSvgIcon-root {
      position: relative;
      top: 3px;
      margin-right: 5px;
    }
  }

  .MuiList-root {
    padding: 0;
    max-width: none;
  }

  .MuiCardHeader-root {
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
    &:hover {
        background-color: #fbfbfb;
    }
  }

  .MuiCardContent-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }

  .MuiButtonBase-root {
    padding: 16px;
  }

  .checklist-item-description {
    svg.MuiSvgIcon-root {
      position: relative;
      top: 3px;
    }
  }
}
