.form-root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 30px;
  flex-grow: 1;

  margin-left: -15px;
  margin-right: -15px;

  .form-buttons-container {
    padding-left: 16px 0px;
  }
}
