.drawer-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-text {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .children {
    margin-top: 32px;
  }
}

.MuiPaper-root.custom-drawer-paper {
  background-color: transparent !important;
}