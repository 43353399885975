
.page-section {
  text-align: left;
  margin-bottom: 20px;

  & > .MuiPaper-root {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .page-section-title {
    font-size: 24px;
    text-align: left;
    font-weight: bold;
  }

  .section-headers {
    margin-bottom: 10px;
  }
}