@import "../../../frontend/theme/colors";

.published-smart-chain-versions-list-container {
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  height: 100vh;
  overflow-y: auto;

  .circular-loader-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .empty-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .empty-text {
      font-size: 12px;
      color: $mid-black;
    }
  }

  .published-smart-chain-versions-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .published-smart-chain-versions-list-header-text {
      font-size: 14px;
      color: $mid-black;

      &.selected {
        font-weight: 600;
      }
    }
  }

  .published-version-item-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .published-version-item {
      display: flex;
      align-items: center;
      gap: 4px;

      .published-version-item-text {
        font-size: 12px;
        color: $mid-black;

        &.selected {
          font-weight: 600;
        }
      }

      svg {
        color: #4caf50;
        font-size: 18px;
      }
    }

    .publish-btn {
      font-size: 12px;
      padding: 0px 12px;
    }
  }
}
