

.temperature-slider {
    text-align: left;

    .MuiInput-root {
        width: 80px;

        .MuiInput-input {
            padding: 0;
            text-align: center;
        }
    }
}