@import "../../theme/colors";

.form-section {
  h2 {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    color: $mid-black;
    font-size: 16px;
    font-weight: 700;
  }

  h6 {
    text-align: left;
    position: relative;
    top: -5px;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-style: italic;
    color: #505050;
  }

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
  &:not(:first-child) {
    padding-top: 20px;
  }

  & > * {
    margin-bottom: 10px;
  }

  // &:nth-child(even) {
  //   background-color: #fafafa;
  // }
}
