@import "../../../frontend/theme/sizes";
@import "../../../frontend/theme/colors";


.smart-chain-edit-page {
  height: calc(100vh - $top-bar-size);
  overflow: hidden;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .smart-chain-main-editor-area {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
  }

  .step-input-fields-column {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    overflow-x: hidden;
    flex-shrink: 0;
  }

  .prompt-editor-view-column {
    //flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }

  .chain-step-output-view-column {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    flex-shrink: 0;
    border-left: 1px solid #ddd;
  }
}

.smart-chain-top-bar-title-area {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: $primary-text-dark;
  h2 {
    color: $primary-text-dark;
    font-style: italic;
  }
}