$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';

.conversation-avatar {
  width: 48px;
  height: 48px;

  .MuiIcon-root {
    font-size: 30px;
  }

  .MuiSvgIcon-root {
    width: 28px;
    height: 28px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}