@import "../../../frontend/theme/colors";

.document-template-edit-page {
  flex-grow: 1;
  display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;


  .document-template-edit-page-tab {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    &.visible {

    }

    &.hidden {
      display: none;
    }

    &.settings {
      padding: 20px;
    }
  }

  .MuiTabs-root {
    background-color: $primary-main;
    border-left: 1px solid #ccc;
  }
}