


.json-schema-editor {
  .MuiGrid-root {
    align-items: flex-start;
  }

  .form-group.field.field-object {
    >:nth-child(1) {
      flex-basis: 30%;
    }
    >:nth-child(2) {
      flex-basis: calc(70% - 60px);
      flex-grow: 0;
      flex-shrink: 1;
    }
    >:nth-child(3) {
      flex-basis: 60px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  .form-group.field.field-array {
    .MuiPaper-root {
      box-shadow: none;
      padding: 0;
      border: 0;
    }
    .MuiBox-root {
      padding: 0;
      margin: 0;
    }
  }
}

.json-schema-form {
  position: relative;

  & > .MuiToggleButton-root.view-toggle-button {
    position: absolute;
    right: -10px;
    top: -10px;
    .MuiSvgIcon-root {

    }
    padding: 4px;
    z-index: 1000;
    background-color: white;
  }
}