
.knowledge-chunk-list-page {
  .knowledge-base-search-widget {
    .MuiInputBase-root {
      margin-left: -1px;
      margin-right: -1px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}