

.smart-chain-whole-chain-test-form {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  padding: 20px;

  .input-column, .output-column {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: stretch;
    align-items: stretch;

    .MuiPaper-root {
      flex-grow: 1;
      padding: 20px;
    }
  }

  .form-group.field-object .MuiGrid-root {
    padding-left: 20px;
  }

  .field-object .field-object {
    h5 {
      font-size: 1.2rem;
    }
  }
}