

.file-preview {
  margin: 10px;
  position: relative;
  overflow: hidden;

  border: 1.5px solid #ececec;

  img {
    width: 100%;
  }

  .no-file-text-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-style: italic;
      color: gray;
    }
  }
}
