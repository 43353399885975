@import '../../../frontend/theme/colors';

.smart-chain-test-samples {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: stretch;

  .search-options-row {
    padding: 10px;
  }

  .test-sample-row {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .test-sample-header {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    align-items: stretch;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    font-size: 0.8rem;
    font-style: italic;
  }

  .test-sample-body {
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: stretch;
    align-items: stretch;
  }

  .test-sample-row.expand-both {
    .test-sample-context {
      flex-basis: calc(45% - 40px);
    }
    .test-sample-result {
      flex-basis: calc(55% - 40px);
    }
  }

  .test-sample-row.expand-left {
    .test-sample-context {
      display: none;
    }
    .test-sample-result {
      flex-basis: calc(100% - 40px);
    }
  }

  .test-sample-row.expand-right {
    .test-sample-context {
      flex-basis: calc(100% - 40px);
    }
    .test-sample-result {
      display: none;
    }
  }

  .test-sample-column {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: stretch;
    align-items: stretch;

    .custom-json-view {
      flex-grow: 1;
    }
    span.sample-date {
      padding: 5px;
    }
  }
  .test-sample-actions {
    display: flex;
    flex-basis: 40px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-direction: column;
    gap: 0px;
    align-items: stretch;
    background-color: #f3fff3;

    .MuiButtonBase-root.reprocess-button {
      flex-grow: 1;
        flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      .MuiCircularProgress-root {
        color: $accent-text;
      }
    }

  }
}