
.import-webpage-page {
  .import-webpage-card {
    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}