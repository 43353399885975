

.about-system-page {
  .page-section > .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex-grow: 1;
    align-items: stretch;
  }

  .logo {
    max-width: 200px;
    opacity: 0.3;
  }
}

