
.prospera-chat-with-bot-widget {
  position: relative;
  top: 0;
  left: 0;
  flex-grow: 1;
  height: 100%;
  width: 100%;

  .MuiPaper-root.MuiCard-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    transition: transform 1s ease-in-out;
    z-index: 1;
    border-radius: 15px;
  }

  .MuiCardHeader-root {
    text-align: left;
  }

  .MuiCardContent-root {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    overflow-y: auto;
  }

  .MuiCardActions-root {

  }

  .conversation-history-box {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .conversation-history-box .message {
    margin-bottom: 10px;
  }


  .card-actions-input-area {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    gap: 10px;
    flex-direction: column;
    padding: 0;
  }

  .prospera-empty-conversation-message {
    position: relative;
    text-align: center;
    top: 45%;
    color: grey;
    font-style: italic;
  }


  .prospera-conversation-action-buttons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  }

  .conversation-background-watermark {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.10;
    pointer-events: none;
  }
}


//.prospera-talk-to-the-bot-button {
//  width: 100px;
//  height: 100px;
//  border-radius: 50px;
//  left: calc(50% - 50px);
//  position: absolute;
//  top: calc(50% - 50px);
//  margin: 10px;
//  z-index: 0;
//}