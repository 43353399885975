@import "../../theme/colors";

.schema-layout-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .schema-fields-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .schema-metadata-title {
    font-weight: 600;
    font-size: 14px;
    color: $mid-black;
  }
}

.field-list-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0px;

  .field-list-item-key {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .field-list-item-actions {
    display: flex;
    gap: 8px;
    align-items: center;

    .remove-field-button {
      color: $error;
      cursor: pointer;

      &:disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }

    .expand-button {
      color: $mid-black;
      cursor: pointer;
    }
  }
}

.field-list-item-content {
  margin-left: 12px;
  .field-content {
    margin-top: 4px;
    margin-left: 16px;

    &.double-indent {
      margin-left: 32px;
    }
  }
}

.array-items-schema-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;

  .array-items-schema-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    margin-bottom: -16px;

    .header-text {
      font-weight: 600;
      font-size: 14px;
      color: $mid-black;
    }
  }

  .array-items-schema-header-actions {
    display: flex;
    gap: 8px;
    align-items: center;

    .expand-button {
      color: $mid-black;
      cursor: pointer;
    }

    .remove-field-button {
      color: $error;
      cursor: pointer;
    }
  }
}
