

.edit-calendar-page {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  .MuiPaper-root {
    padding: 20px;
  }
}