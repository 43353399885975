.avatar-selector {
  &.MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px 30px;
    & > .MuiFormControlLabel-root {
      flex-basis: 25%;
    }
  }


  .avatar-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
}