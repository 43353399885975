
.outbound-attempt-records-list {
  .outbound-attempt-record-item {
    .MuiListItemText-root {
      span {
        color: rgb(0,0,0,0.87) !important;
        text-decoration: none !important;
      }
    }
  }
}