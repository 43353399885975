


.email-configuration-page {

  .email-address-field-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
    gap: 20px;
    & > {
      flex-grow: 1;
    }

    .email-address-field {
      width: 400px;
    }
  }

  .email-address-link {
    color: blue;
    text-decoration: underline;
  }
}