
.new-smart-chain-binding-page {
  .new-smart-chain-binding-card {
    .MuiCardContent-root {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      gap: 10px;
    }
  }
}