
.custom-json-view {
  position: relative;

  & > .MuiToggleButton-root {
    position: absolute;
    right: 0;
    top: 0;
    .MuiSvgIcon-root {

    }
    padding: 4px;
    z-index: 1000;
    background-color: white;
  }

  .MuiListItemIcon-root {
    min-width: 34px;
  }

  .MuiButtonBase-root, .MuiListItem-root {
    padding-left: 4px;
  }

  .MuiTypography-root {
    font-size: 14px !important;
    font-family: monospace !important;
  }

  pre {
    background-color: #f5f5f5;
    border-radius: 5px;
    overflow: auto;
    font-size: 14px;
    font-family: monospace;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 10px;
    flex-grow: 1;
  }

  .MuiTypography-root {
    white-space: pre-wrap;
  }

  .MuiButtonBase-root.MuiListItemButton-root {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .MuiAvatar-root {
    font-size: 14px;
    font-weight: bold;
    padding-top: 2px;
    padding-left: 1px;
  }

  .MuiListItem-root {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .MuiList-root {
    padding: 0;
  }


  .custom-json-view-object-root {
     .MuiCollapse-root {
       .MuiCollapse-root {
         padding-left: 2px;
         border-left: 2px dashed #999;
         margin-left: 12px;
       }
     }
  }

  .field-value-group,.field-value-expanding-text {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    white-space: pre-wrap;
    word-break: break-word;

    .field-value-name {
      flex-basis: 40%;
      flex-grow: 1;
      flex-shrink: 1;
    }
    .field-value-operator {
      flex-basis: 10%;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;
    }
    .field-value-value {
      flex-basis: 50%;
      flex-grow: 1;
      flex-shrink: 1;
    }
    .field-value-icon {
      flex-basis: 30px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}