

.voice-configuration-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  align-items: stretch;

  .voice-configuration-card {
    text-align: left;
  }

  .country-selector-for-available-phone-numbers {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    .phone-country-code {
      min-width: 40px;
    }
  }

  .human-transfer-phone-textfield {
    width: 100%;
    flex-direction: row;
  }

}