.comma-separated-input {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .chips-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}
