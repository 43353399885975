

.smart-chain-step-input-field-list {
  padding: 16px 8px 48px 8px;

  h5 {
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 8px;
  }
}