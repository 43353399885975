

.prospera-chat-with-bot-page {
  height: 80vh;
}

.prospera-bot-controls {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}