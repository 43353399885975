

.agent-action-list {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  &.loading {
    justify-content: center;
  }
  padding-bottom: 100px;

  .agent-action-list-inner {
    width: 100%;
  }

  .title-row {
    .MuiTypography-root {
      font-weight: bold;
    }
  }
}


.available-action-additional-details-tooltip.MuiTooltip-tooltip {
  font-size: 14px;
  background-color: white;
  color: black;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
  border-radius: 4px;
  padding: 20px;
  max-width: 30vw;
  white-space: pre-wrap;
  max-height: 30vh;
  overflow-y: auto;
}