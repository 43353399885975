

.llm-model-list {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  &.loading {
    justify-content: center;
  }
  align-items: center;
  overflow-y: auto;
  flex-grow: 1;
  flex-shrink: 1;
  padding-bottom: 100px;

  .llm-model-list-inner {
    width: 100%;
  }

  .title-row {
    .MuiTypography-root {
      font-weight: bold;
    }
  }
}