



.available-phone-number-selector {

  .field-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .field-box-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 10px;
  }

  .field-loader-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100px;
    flex-basis: 100px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}