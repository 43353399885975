@import "../theme/colors";
@import "../theme/sizes";

.page-wrapper-without-menu {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  height: 100vh;
}

.page-wrapper-without-menu .below-top-bar {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  .main-content-area {
    background-color: #fbfbfb;
    padding-top: 40px;
    padding-left: 40px;
    padding-right: 60px;
    padding-bottom: 60px;

    &.no-padding {
      padding: 0;
    }

    &.no-top-bar{
      height: 100vh;
    }

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

    overflow-y: auto;
    height: calc(100vh - $top-bar-size - 100px);
  }
}
