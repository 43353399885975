

.default-intake-configuration-page {
  .MuiPaper-root {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    align-items: stretch;
  }
}